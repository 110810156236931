import React from 'react'
import thelandbuckslogo  from "../images/logo.jpg"
function About() {
  return (
    <div>
           <div className="album py-0">
                <div className="container">
                    <center>
                    <h1 className='mb-3'><b>About The Land Bucks</b></h1>
                    </center>
                    <div className="row">
                        <div className="col-md-4 mt-3 me-5">
                        <img src={thelandbuckslogo} className="card-img-top" alt="landsellnadia"/>
                              
                                
                        </div>
                        <div className="col-md-7 mt-3">
                           
                        <div className="card-body">
                                    <p style={{color:"grey"}} className="card-text justufytext">At <b>The Land Bucks</b>, we believe in the power of land ownership and the opportunities it brings. Our mission is to make the process of purchasing land simple, transparent, and accessible for everyone. Whether you're looking for a plot to build your dream home, start a farm, or make a long-term investment, we are here to guide you every step of the way.</p>
                                    <p style={{color:"grey"}} className="card-text justufytext">We specialize in offering high-quality land at affordable prices across various regions, ensuring that our clients find the perfect piece of land that meets their needs. Our knowledgeable team works hard to research each property thoroughly, ensuring that all listings are legally sound and ready for purchase.</p>
                                    <p style={{color:"grey"}} className="card-text justufytext">We pride ourselves on being a trusted partner in land acquisition, providing personalized service and straightforward communication. With flexible financing options and clear contracts, The Land Bucks makes land ownership within reach, no matter your budget or vision.</p>
                                   <h6>Explore our listings, and let us help you find your perfect piece of land today.</h6>
                                   <a href="customer-care" className="nadialistyourland">Customer Care <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></a>
                                </div>
                            
                               
                        </div>
                    </div>
                </div>
            </div>

    </div>
  )
}

export default About
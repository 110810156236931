import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from './components/inc/Footer';
import ListProperty from './components/pages/ListProperty';
import Nadialand from './components/pages/Nadialand';
import AboutusPage from './components/pages/AboutusPage';
import Customercare from './components/pages/Customercare';



function App() {
  return (
    <Router>
   
    <div>
      
      <Routes>


      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/ListProperty" element={<ListProperty/>}/>
      <Route axact path="/nadialand" element={<Nadialand/>}/>
      <Route axact path="/about-us" element={<AboutusPage/>}/>
      <Route axact path="/customer-care" element={<Customercare/>}/>
 

        
      </Routes>
 
  
    </div>
<Footer/>
    </Router>

  );
}

export default App;

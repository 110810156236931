import React from 'react';
// import './Footer.css';
import { Link } from 'react-router-dom';


import "./Footer.css";


function Footer() {
    return (


        <div className="landnadia">
            <br></br>

            <center>
                <div className="album py-2">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item"><a style={{ color: "black" }} href="#">MEDIA</a></li>
                                <li className="breadcrumb-item"><a style={{ color: "black" }} href="#">BUSINESS DEAL</a></li>
                                <li className="breadcrumb-item"><a style={{ color: "black" }} href="#">CUSTOMER GUIDE</a></li>
                                <li className="breadcrumb-item"><a style={{ color: "black" }} href="#">TRUSTED LEGAL PROPERTY GUIDE</a></li>

                                <li className="breadcrumb-item"><a style={{ color: "black" }} href="customer-care">CUSTOMER CARE</a></li>
                            </ol>
                        </nav>
                        <br />

                    </div>
                </div>

            </center>
            {/* <div className="p-3 mb-0 bg-dark text-black"> */}

            <div className="album py-3">

                <div className="container">

                    <div className="row">
                        <div className="col-md-4">

                            <h6 style={{ color: "black" }}>THE LAND BUCKS</h6>
                            <hr style={{ color: "black" }} />

                            <ul className="list-unstyled">

                                <h6 style={{ color: "black" }} className="card-text "> <b>THE DIRECTOR,</b></h6>

                                <p style={{ color: "black" }} className="card-text mb-1"><small> The Land Bucks <br></br>Land Trusted Website , Best Land & Buy in Nadia West Bengal </small></p>


                                {/* <a href="tel: +91 000568541">
                                    <p style={{ color: "black" }} className="mb-2" >    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                    </svg> +91 000568541  (Director Cell)
                                    </p> </a> */}


                                <a style={{ color: "black" }} href='mailto:  '>Email:  info@update</a>

                                {/* <ReactWhatsapp number="+91 9434168565" className="btn btn-success" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp> */}

                            </ul>
                        </div>

                        <div className="col-md-2">
                            <h6 style={{ color: "black" }}>THE LAND BUCKS</h6>
                            <hr />
                            <ul className="list-unstyled">



                                <a href='/' style={{ color: "black" }} >

                                    <li style={{ color: "black" }} className="mb-2"><a><small>Home</small></a></li>
                                </a>
                                <a href='about-us' style={{ color: "black" }} >
                                    <li style={{ color: "black" }} className="mb-2"><a ><small>About Us</small></a></li></a>
                                <a href='nadialand' style={{ color: "black" }} >
                                    <li style={{ color: "black" }} className="mb-2"><a ><small> Nadia Land</small></a></li></a>
                            



                            </ul>

                        </div>
                        <div className="col-md-2">

                            <h6 style={{ color: "black" }}>Quick Link</h6>
                            <hr />

                            <ul class="list-unstyled">

                                <a href='#' style={{ color: "black" }} >
                                    <li style={{ color: "black" }} className="mb-2"><small>Flat</small></li></a>
                                <a href='#' style={{ color: "black" }} >
                                    <li style={{ color: "black" }} className="mb-2"><small>Featured </small></li></a>

                                <a style={{ color: "black" }} href='#'>
                                    <li style={{ color: "black" }} className="mb-2"><small>Project</small></li></a>
                                <a style={{ color: "black" }} href='#'>
                                    <li style={{ color: "black" }} className="mb-2"><small>Gallery</small></li></a>

                            </ul>
                        </div>
                        <div className="col-md-2">

                            <h6 style={{ color: "black" }}> Other Link</h6>
                            <hr />

                            <ul class="list-unstyled">

                                <a href='customer-care' style={{ color: "black" }}>
                                    <li style={{ color: "black" }} className="mb-2"><small>Customer Care </small></li></a>


                                <a href='#' style={{ color: "black" }}>
                                    <li style={{ color: "black" }} className="mb-2"><small>  Terms & Condition</small></li></a>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h6 style={{ color: "black" }}>Social Link</h6>
                            <hr />
                            <ul className="list-unstyled">
                                <li className="mb-2"> <a style={{ color: "black" }} href="https://www.facebook.com/TheLandBucks" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "black" }} className="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                    </svg> <small> Facebook</small></a> </li>

                                <li className="mb-2"> <a style={{ color: "black" }} href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" style={{ color: "black" }} viewBox="0 0 16 16">
                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                    </svg> <small> Instagram</small></a> </li>

                                <li className="mb-2"> <a style={{ color: "black" }} href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "black" }} width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                    </svg><small> Twitter </small></a> </li>






                            </ul>

                        </div>





                    </div>
                </div>
            </div>



            <div className="album py-3">

                <div className="container">

                    <div className="row">
                        <div className="col-md-4">
                            <Link to="/">
                                {/* <img src={logo}  alt="logombdharandsonjewellers"/>  */}
                            </Link>
                        </div>

                        <div className="col-md-4">
                            <p style={{ color: "black" }}> Copyright © 2024, THE LAND BUCKS </p>

                        </div>
                        {/* <div className="col-md-4">
                            <small style={{ color: "black" }}> All Right Researve : <a style={{ color: "black" }} href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> MR. INDIAN CODER</a> </small>
                            <small>  <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> </a> </small>
                            <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="70" height="25" alt="mr.indiancoder" /> </a>
                        </div> */}

                    </div>
                </div>
            </div>

        </div>
        //  </div>





    );
}
export default Footer;
import React from 'react'
import "./Navbar.css"
import {Link} from "react-router-dom";
import logo from "../images/logo.jpg";

function Navbar() {
  return (
    <div>
      <div className='fixed-top'>
        <div className="bishakhagroup">
          <div className="album py-1">

            <div className="container">

              <div className="row ">

                <div className="col-md-4" >
                  <div className="d-flex justify-content-between align-items-center">

                   

                    <a href='tel: +91 9933798896'>
                      <div className='btn-grad31' >Help Line Number</div>
                      
                    </a>
<div className='colortextnav'>
 <a href='ListProperty'>List your property</a>
 </div>

                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
          <div className="container-fluid">
           <Link to="/">
           <img src={logo} height="60px" width="60px" alt="the land bucks logo"/>
           </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page" href="#">HOME </Link>
                </li>
               
                <li className="nav-item">
                  <Link to="/" className="nav-link" href="#">RECENT PROJECT</Link>
                </li>
                <li className="nav-item">
                  <Link to="/nadialand" className="nav-link" href="#">NADIA LAND</Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                   THE LAND BUCKS OWN LAND
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="#">Krishnagar</a></li>
                    <li><a className="dropdown-item" href="#">Karimpur</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#">Kolkata</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/customer-care" className="nav-link" href="#">CUSTOMER CARE</Link>
                </li>
              </ul>

         <a href='tel: +91 9933798896'>
                <div className="btn-grad31" type="submit">Call Now</div>
                </a>
             
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar
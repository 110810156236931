



import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyC-MwyaQHgqD5iyoLinLiDXqlF3K8zbx54",
    authDomain: "theland-bucks.firebaseapp.com",
    projectId: "theland-bucks",
    storageBucket: "theland-bucks.appspot.com",
    messagingSenderId: "62980196192",
    appId: "1:62980196192:web:b2df6f15d4d33669944005",
    measurementId: "G-MQBZN81XDG"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;



